import React from "react";
import donaldImage from "./donald.png"; // Ensure you import the image correctly

function Content() {
  return (
    <div id="content">
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        <div
          style={{
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: "30%",
            minWidth: "200px",
            maxWidth: "70%",
          }}
        >
          <img src={donaldImage} alt="funny donald duck face" />
        </div>
        <div style={{ flexGrow: 2, flexShrink: 2, flexBasis: "60%" }}>
          <p>hello and welcome to my website</p>
          <p>
            i am currently a phd student in the chemistry department at mcgill
            university co-supervised by dr. amy blum and dr. gonzalo cosa
          </p>
          <p>
            i am interested in a lot of things, including ai (statistics),
            photonics, chips, post-modern natural philosophy, and smooth jazz
          </p>
        </div>
      </div>
    </div>
  );
}

export default Content;
